<template>
  <div class="page-container">
    <progress-step :step="page.step"></progress-step>
    <div class="page-content">
      <v-card flat>
        <v-card-text>
          <div class="bold-f-s">{{text}}</div>
        </v-card-text>
        <v-card-text class="text-center">
          <div>
            <div class="bold-f f-s-20">
              Amount Financed
            </div>
            <div class="f-s-40 f-w-700 l-h-2em" :style="`color: ${$vuetify.theme.themes.light.success}`">
              {{toCurrency(paymentPlan.approvedAmount)}}
            </div>
          </div>
          <v-row
            style="border-right: 1px solid #eee; border-bottom: 1px solid #eee;"
          >
            <v-col
              cols="4" v-for="detail in loanDetails" :key="detail.title"
              class="p-5"
              style="border-top: 1px solid #eee; border-left: 1px solid #eee;"
            >
              <div class="f-s-12" style="min-height: 40px">{{detail.title}}</div>
              <div class="bold-f f-s-16">{{detail.role(paymentPlan[detail.filedKey])}}</div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-subtitle>
          <div class="f-s-12">
            You may follow the given minimum payment schedule or make paydowns on your loan by calling our support department. Remember that with {{portfolio.displayName}} you have the flexibility of paying your loan in full at any time without prepayment fees or penalties.
          </div>
        </v-card-subtitle>
      </v-card>
    </div>
    <div class="page-action">
      <v-btn
        large
        block
        color="primary"
        @click="next">NEXT
      </v-btn>
    </div>
    <v-overlay
      absolute
      :value="overlay"
    >
      <v-icon class="mdi mdi-spin mdi-loading" large></v-icon>
    </v-overlay>
  </div>
</template>

<script>
import { PROFILE_SECTION_LAYOUT_SETTING } from '@/js/layout-setting/layout-setting'
import { Tool, DataType } from '../../js/core'
import { OriginationApi } from '../../api/application'
import ProgressStep from '../../components/progress/ProgressStep'
const tool = new Tool()

export default {
  components: { ProgressStep },
  data () {
    return {
      page: DataType.PAGES.PREVIEW,
      text: 'Please review your loan summary below',
      layoutSetting: PROFILE_SECTION_LAYOUT_SETTING,
      loanDetails: [
        // { title: 'Amount Financed', filedKey: 'amount' },
        { title: 'Effective Date', filedKey: 'effectiveDate', role: this.toString },
        { title: 'First Due Date', filedKey: 'firstDebitDate', role: this.toString },
        { title: 'Maturity Date', filedKey: 'lastDebitDate', role: this.toString },
        { title: 'Payment Frequency', filedKey: 'payrollFrequency', role: this.toString },
        { title: 'First Payment Amount', filedKey: 'firstDebitAmount', role: this.toCurrency },
        { title: 'Regular Payment Amount', filedKey: 'regularAmount', role: this.toCurrency },
        { title: 'Finance Charge', filedKey: 'totalInterest', role: this.toCurrency },
        { title: 'Total of Payments', filedKey: 'totalAmount', role: this.toCurrency },
        { title: 'APR', filedKey: 'apr', role: this.toApr }
      ],
      paymentPlan: {
        approvedAmount: 0,
        apr: 0
      },
      paymentCalculateData: {},
      paymentData: {},
      paymentFreeData: {},
      installmentListData: {},
      overlay: true,
      step: 'bank',
      portfolio: {}
    }
  },
  methods: {
    next () {
      const stepMessage = {
        currect: DataType.PAGES.PREVIEW,
        next: DataType.PAGES.SIGNATURE
      }
      this.$eventBus.$emit('sendStepToSloth', stepMessage)
      OriginationApi.enteredAndLeft(
        localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
        DataType.PAGES.PREVIEW.step,
        DataType.PAGES.SIGNATURE.step,
        localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
      )
      this.$router.push('/authentication/signature')
    },
    toCurrency (num) {
      return tool.formatCurrency(num)
    },
    toApr (num) {
      return (num * 100).toFixed(2) + '%'
    },
    toString (str) {
      return str
    }
  },
  async created () {
    const _this = this
    if (parseInt(localStorage.getItem(DataType.COOKIE_KEY.LOAN_TYPE)) === DataType.LoanTypeEnum.MULTIPLE_ADVANCE_LOAN.value) {
      _this.text = 'Please review your Initial Advance loan summary below'
    }
    _this.$store.commit('setCurrentPage', _this.page)
    this.loanId = localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID)
    _this.portfolio = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO))
    OriginationApi.generatePaymentPlanByLoanId({ loanId: this.loanId, portfolioId: localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID) }, function (result) {
      _this.paymentPlan = result.data
      _this.paymentCalculateData = result.paymentCalculate
      _this.paymentData = result.payment
      _this.paymentFreeData = result.paymentFree
      _this.installmentListData = result.installmentList
      _this.overlay = false
    })
  }
}
</script>
